import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import GatsbyImage, { FluidObject, GatsbyImageProps } from "gatsby-image"

// Utils
import { getAspectRatio } from "../utils/styleHelpers"

// Styling
import { applyResponsive, Breakpoint } from "../styles/responsive"
import colors from "../styles/colors"
import { GridCol, GridRow } from "./grid"

// Components
import Text from "./Text"

// Constants
import { ASPECT_RATIO } from "../constants/ourProjects"

// Types
import { IProject } from "../common/models/ourProjects"

const Project: React.FCS<IProject> = ({ image, title, content, className }) => {
  const [fluidObj, setFluidObj] = useState<FluidObject | FluidObject[]>()

  useEffect(() => {
    setFluidObj({
      ...image.fluid,
      aspectRatio: getAspectRatio(ASPECT_RATIO),
    })

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const handleResize = () => {
    // debounce
    let timeout

    clearTimeout(timeout)

    timeout = setTimeout(() => {
      setFluidObj({
        ...image.fluid,
        aspectRatio: getAspectRatio(ASPECT_RATIO),
      })
    }, 250)
  }

  return (
    <Container className={className}>
      <GridCol m={6} mOffsetLeft={2} l={10} lOffsetLeft={1}>
        <Wrapper>
          {image.fluid && fluidObj && (
            <ImageWrapper m={6} mMaxCols={6} l={4} lMaxCols={10}>
              <StyledGatsbyImage fluid={fluidObj} alt={image.alt ?? ""} />
            </ImageWrapper>
          )}
          <ContentWrapper m={6} mMaxCols={6} l={6} lMaxCols={10}>
            {title && (
              <Title marginBottom={16} tag="h3" textStyle="title">
                {title}
              </Title>
            )}
            {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
          </ContentWrapper>
        </Wrapper>
      </GridCol>
    </Container>
  )
}

const Container = styled(GridRow)`
  padding: 0;

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      position: relative;
      z-index: 0;
      padding-bottom: 50px;

      ::after {
        content: "";
        display: block;
        z-index: -1;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 65%;
        height: 104px;
        background-color: ${colors.primary};
      }
    `
  )}

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      padding-bottom: 60px;

      ::after {
        width: 50%;
        height: 120px;
      }
    `
  )}

  ${applyResponsive(
    { from: Breakpoint.XL },
    css`
      ::after {
        left: calc((100vw - 1440px) / -2);
        width: 50vw;
      }
    `
  )}
`

const Wrapper = styled(GridRow)`
  align-items: stretch;
  justify-content: space-between;
`

const ImageWrapper = styled(GridCol)`
  margin-bottom: 16px;
  width: 100%;

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      margin: 0;
      width: auto;
    `
  )}
`

const StyledGatsbyImage = styled(GatsbyImage)<GatsbyImageProps>`
  flex-shrink: 0;
  height: 100%;
`

const ContentWrapper = styled(GridCol)`
  margin: 0 -20px;
  padding: 24px 20px 32px;
  width: 100%;
  background-color: ${colors.neutral};

  ${applyResponsive(
    { to: Breakpoint.M },
    css`
      max-width: unset;
    `
  )}

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      margin: 0;
      padding: 32px 24px;
    `
  )}

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      padding: 56px 48px;
    `
  )}
`

const Title = styled(Text)`
  border-bottom: 4px solid ${colors.black};
  padding-bottom: 16px;

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      display: inline-block;
      padding-bottom: 0;
    `
  )}

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      margin-bottom: 24px;
      padding-bottom: 8px;
    `
  )}
`

export default Project
