import React, { useState } from "react"
import styled, { css } from "styled-components"

// Utils
import { findBreakpoint } from "../utils/styleHelpers"

// Styling
import { applyResponsive, Breakpoint } from "../styles/responsive"

// Components
import { GridCol, GridRow } from "./grid"
import SectionHeader from "./SectionHeader"
import PreviewCard from "./PreviewCard"
import Button, { ButtonVariant } from "./Button"

// Constants
import {
  INITIAL_ITEM_COUNT,
  NUMBER_OF_ITEMS_TO_ADD,
} from "../constants/previews"

// Types
import { INewsPreviews } from "../common/models/newsPreviews"
import { IPreviewCard } from "../common/models/previewCard"

const NewsPreviews: React.FCS<INewsPreviews> = ({ news }) => {
  const [previewsToShow, setPreviewsToShow] = useState<IPreviewCard[]>(
    news.slice(0, INITIAL_ITEM_COUNT)
  )

  const handleShowMore = () => {
    setPreviewsToShow(() => {
      const currentDisplayedNewsCount = previewsToShow.length
      const newDisplayedNewsCount =
        currentDisplayedNewsCount + NUMBER_OF_ITEMS_TO_ADD

      return news.slice(0, newDisplayedNewsCount)
    })
  }

  return (
    <section id="news">
      <SectionHeader title="NEWS" />
      <GridRow>
        <PreviewsWrapper m={8} mOffsetLeft={1} l={9} lOffsetLeft={1.5}>
          {previewsToShow.map((newsItem) => (
            <CardWrapper
              key={newsItem.linkUrl}
              m={4}
              mMaxCols={8}
              l={3}
              lMaxCols={9}
            >
              <PreviewCard variant="news" {...newsItem} />
            </CardWrapper>
          ))}
        </PreviewsWrapper>
        {news.length > INITIAL_ITEM_COUNT && (
          <GridCol m={3} mOffsetLeft={3.5} l={3} lOffsetLeft={4.5}>
            <StyledButton
              caption="Show more news"
              variant={ButtonVariant.Filled}
              color="black"
              textColor="white"
              hoverColor="white"
              hoverTextColor="black"
              hoverBorderColor="black"
              activeBorderColor="black"
              onClick={handleShowMore}
              disabled={news.length <= previewsToShow.length}
            />
          </GridCol>
        )}
      </GridRow>
    </section>
  )
}

const PreviewsWrapper = styled(GridCol)`
  :not(:last-child) {
    margin-bottom: 24px;
  }

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: space-between;

      :not(:last-child) {
        margin-bottom: 40px;
      }
    `
  )}

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      justify-content: flex-start;

      :not(:last-child) {
        margin-bottom: 32px;
      }
    `
  )}
`

const CardWrapper = styled(GridCol)`
  margin-bottom: 24px;

  :last-child {
    margin: 0;
  }

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      display: flex;
    `
  )}

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      margin-bottom: 32px;

      :last-child {
        margin-bottom: 32px;
      }

      :not(:last-child):not(:nth-child(3n)) {
        margin-right: ${findBreakpoint("l")?.gutterWidth}px;
      }
    `
  )}
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default NewsPreviews
