import { Variant } from "../common/models/previewCard"
import { AspectRatio } from "../utils/styleHelpers"

type PreviewCardImageAspectRatio = {
  [key in Variant]: AspectRatio
}

export const ASPECT_RATIO: PreviewCardImageAspectRatio = {
  news: { s: 2.3, m: 1.4 },
  story: { s: 2.3, m: 1.79 },
}
