import React from "react"
import styled, { css } from "styled-components"

// Styling
import { applyResponsive, Breakpoint } from "../styles/responsive"

// Components
import SectionHeader from "./SectionHeader"
import Project from "./Project"

// Types
import { IOurProjects } from "../common/models/ourProjects"

const OurProjects: React.FC<IOurProjects> = ({ projects }) => (
  <Section id="our_projects">
    <SectionHeader title="Our Projects" />
    {projects.length > 0 &&
      projects.map((project) => (
        <StyledProject key={project.title} {...project} />
      ))}
  </Section>
)

const Section = styled.div``

const StyledProject = styled(Project)`
  :not(:last-child) {
    margin-bottom: 40px;
  }

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      :not(:last-child) {
        margin-bottom: 80px;
      }
    `
  )}
`

export default OurProjects
