import React from "react"
import styled, { css } from "styled-components"

// Styling
import { applyResponsive, Breakpoint } from "../styles/responsive"
import colors from "../styles/colors"

// Components
import { GridCol } from "./grid"
import Text from "./Text"
import SocialMediaButton from "./SocialMediaButton"

// Types
import { ISocialCard } from "../common/models/socialCard"
import Container from "./Container"

const SocialCard: React.FCS<ISocialCard> = ({
  title,
  content,
  socialMedia,
}) => (
  <PageBackground>
    <Container>
      <PageContainer>
        <ContentWrapper
          mOffsetLeft={1}
          mOffsetRight={1}
          lOffsetLeft={1}
          lOffsetRight={0}
        >
          <Title color="white" textStyle="title" marginBottom={24}>
            {title}
          </Title>
          <Content dangerouslySetInnerHTML={{ __html: content }} />
        </ContentWrapper>
        {socialMedia.length > 0 && (
          <SocialsWrapper
            mOffsetLeft={1}
            mOffsetRight={1}
            lOffsetLeft={0}
            lOffsetRight={1}
          >
            {socialMedia.map((item) => (
              <StyledSocialMediaButton
                key={item.url}
                size="large"
                url={item.url}
                type={item.type}
              />
            ))}
          </SocialsWrapper>
        )}
      </PageContainer>
    </Container>
  </PageBackground>
)

const PageBackground = styled.div`
  width: 100%;
  background-color: ${colors.black};
`
const PageContainer = styled.div`
  padding: 32px 20px 72px;
  color: ${colors.white};

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      padding: 40px 0;
    `
  )}

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      display: flex;
      justify-content: space-between;
      padding: 56px 0;
    `
  )}
`

const ContentWrapper = styled(GridCol)`
  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      flex-grow: 0;
      width: 40%;
    `
  )}
`

const Title = styled(Text)``

const Content = styled.div`
  margin-bottom: 80px;

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      margin-bottom: 32px;
    `
  )}

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      margin-bottom: 0;
    `
  )}
`

const SocialsWrapper = styled(GridCol)`
  display: flex;
  justify-content: center;
  align-items: center;

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      justify-content: space-between;
    `
  )}

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      flex-grow: 0;
      width: 30%;
    `
  )}
`

const StyledSocialMediaButton = styled(SocialMediaButton)`
  :not(:last-child) {
    margin-right: 48px;
  }

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      :not(:last-child) {
        margin-right: 16px;
      }
    `
  )}
`

export default SocialCard
