import React from "react"
import styled, { css } from "styled-components"

// Utils
// import { findBreakpoint } from "../utils/styleHelpers"

// Styling

// Components
import SectionHeader from "./SectionHeader"
import SocialCard from "./SocialCard"

// Types
import { IYouCanHelp } from "../common/models/youCanHelp"
import { applyResponsive, Breakpoint } from "../styles/responsive"
import EmailLayout from "./EmailLayout"
import UpdatedWrapper from "./UpdatedWrapper"
import { GridCol, GridRow } from "./grid"
import Container from "./Container"
import CreateSocialMediaPost from "./CreateSocialMediaPost"

const YouCanHelp: React.FC<IYouCanHelp> = (props: IYouCanHelp) => {
  const { title, content, socialCard, shareCard } = props
  return (
    <Section id="you_can_help">
      <SectionHeader title={title} />
      <Container>
        <ContentWrapper>
          <GridCol m={6} mOffsetLeft={2} l={8} lOffsetLeft={2}>
            <Content dangerouslySetInnerHTML={{ __html: content }} />
          </GridCol>
        </ContentWrapper>
      </Container>
      <EmailLayoutWrapper>
        <EmailLayout {...props}></EmailLayout>
      </EmailLayoutWrapper>
      <SocialCardWrapper>
        <SocialCard {...socialCard} />
      </SocialCardWrapper>
      <FieldsWrapper>
        <UpdatedWrapper></UpdatedWrapper>
      </FieldsWrapper>
      <CreateSocialMediaPost {...shareCard} />
    </Section>
  )
}

const Section = styled.section`
  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      position: relative;
      z-index: 0;
      padding-bottom: 50px;
    `
  )}

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      padding-bottom: 60px;
    `
  )}

  ${applyResponsive({ from: Breakpoint.XL }, css``)}
`

const ContentWrapper = styled(GridRow)`
  margin-bottom: 32px;
  padding: 0;

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      margin-bottom: 40px;
    `
  )}

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      :not(:last-child) {
        margin-bottom: 32px;
      }
    `
  )}
`

const Content = styled.div`
  > p:last-child {
    margin-bottom: 0;
  }
  padding: 0 20px;
`

const EmailLayoutWrapper = styled.div``

const SocialCardWrapper = styled.div``

const FieldsWrapper = styled.div``

export default YouCanHelp
