import React from "react"
import styled, { css } from "styled-components"

// Styling
import { applyResponsive, Breakpoint } from "../styles/responsive"
import colors from "../styles/colors"
import textStyles from "../styles/typography"

// Components
import { GridRow, GridCol } from "./grid"
import Text from "./Text"
import Button, { ButtonVariant } from "./Button"
import Link from "./Link"

// Types
import Container from "./Container"
import { IYouCanHelp } from "../common/models/youCanHelp"
import Input from "./Input"
import { useState } from "react"

const EmailLayout: React.FCS<IYouCanHelp> = ({
  emailSectionTitle,
  emailDescription,
  emailRecipient,
  emailSubject,
  emailBody,
}) => {
  const [copiedCounter, setCopiedCounter] = useState<number>(0)
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")

  const getEmailBody =
    firstName.length || lastName.length
      ? emailBody.replace("[YOUR NAME HERE]", `${firstName} ${lastName}`)
      : "" + emailBody

  const unescapeHTML = (str: string) =>
    str.replace(
      /&amp;|&lt;|&gt;|&#39;|&quot;/g,
      (tag: any) =>
        (({
          "&amp;": "&",
          "&lt;": "<",
          "&gt;": ">",
          "&#39;": "'",
          "&quot;": '"',
        } as any)[tag] || tag)
    )

  const getParsedBody = () =>
    unescapeHTML(getEmailBody)
      .split("<p>")
      .join("")
      .split("</p>")
      .join("\r\n \r\n")
      .split("<br>")
      .join("\r\n")
      .split("<br />")
      .join("\r\n")
      .split("<br/>")
      .join("\r\n")

  const sendEmail = () => {
    const parsedEmailBody = encodeURIComponent(getParsedBody())
    window.location.href = `mailto:${emailRecipient}?subject=${emailSubject}&body=${parsedEmailBody}`
  }

  const copyEmail = () => {
    const parsedEmailBody = getParsedBody()
    copyTextToClipboard(parsedEmailBody)
    setCopiedCounter(copiedCounter + 1)
  }

  function fallbackCopyTextToClipboard(text: string) {
    const textArea = document.createElement("textarea")
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = "0"
    textArea.style.left = "0"
    textArea.style.position = "fixed"

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      const successful = document.execCommand("copy")
      const msg = successful ? "successful" : "unsuccessful"
      console.info("Fallback: Copying text command was " + msg)
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err)
    }

    document.body.removeChild(textArea)
  }
  function copyTextToClipboard(text: string) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text)
      return
    }
    navigator.clipboard.writeText(text).then(
      () => {
        console.info("Async: Copying to clipboard was successful!")
      },
      (err) => {
        console.error("Async: Could not copy text: ", err)
      }
    )
  }

  const renderEmailButtons = () => (
    <>
      <Fields>
        <FirstRow>
          <Input
            label="First name"
            onChange={(e) => setFirstName(e.target.value)}
          ></Input>
          <Input
            label="Last name"
            onChange={(e) => setLastName(e.target.value)}
          ></Input>
        </FirstRow>
      </Fields>
      <ButtonsWrapper>
        <StyledButton
          caption="Send email"
          variant={ButtonVariant.Filled}
          color="primary"
          textColor="black"
          hoverColor="primaryLight"
          activeBorderColor="black"
          onClick={() => sendEmail()}
        />
        <StyledButton
          caption={
            copiedCounter > 0
              ? "Copied" + "!".repeat(Math.min(copiedCounter, 8))
              : "Copy text"
          }
          variant={ButtonVariant.Filled}
          color="primary"
          textColor="black"
          hoverColor="primaryLight"
          activeBorderColor="black"
          onClick={() => copyEmail()}
        />
      </ButtonsWrapper>
    </>
  )

  return (
    <PageWrapper>
      <PageContainer>
        <ContentWrapper>
          <Layout>
            <StyledRow>
              <GridCol s={2} m={6} mOffsetLeft={2} l={6} lOffsetLeft={0}>
                <Description>
                  <Title tag="h3" textStyle="title" marginBottom={24}>
                    {emailSectionTitle}
                  </Title>
                  <EmailBody
                    dangerouslySetInnerHTML={{ __html: emailDescription }}
                  ></EmailBody>
                  <DesktopButtons>{renderEmailButtons()}</DesktopButtons>
                </Description>
              </GridCol>
              <GridCol s={2} m={6} mOffsetLeft={2} l={6} lOffsetLeft={0}>
                <Letter>
                  <Header>
                    <div>
                      <EmailTitlePart>To</EmailTitlePart>
                      <Email
                        type="external"
                        url={`mailto:${emailRecipient}`}
                        caption={emailRecipient}
                        textStyle="body"
                      />
                    </div>
                    <div>
                      <EmailTitlePart>Subject</EmailTitlePart> {emailSubject}
                    </div>
                  </Header>
                  <EmailBody
                    dangerouslySetInnerHTML={{ __html: getEmailBody }}
                  ></EmailBody>
                </Letter>
              </GridCol>
            </StyledRow>
            <MobileButtons>{renderEmailButtons()}</MobileButtons>
          </Layout>
        </ContentWrapper>
      </PageContainer>
    </PageWrapper>
  )
}

const PageWrapper = styled.div`
  width: 100%;
  background-color: ${colors.neutral};
  padding: 32px 0 72px;

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      padding: 32px 20px 72px;
    `
  )}
`

const PageContainer = styled(Container)`
  margin: 0 auto;
  color: ${colors.white};
  display: flex;

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      padding: 40px 0;
    `
  )}

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      display: flex;
      justify-content: space-between;
      padding: 85px 0;
    `
  )}
`

const ContentWrapper = styled(GridRow)`
  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      flex-grow: 0;
      width: 100%;
    `
  )}
`

const Title = styled(Text)``

const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-right: 66px;
  line-height: 28px;
`

const Letter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  padding: 16px;
  margin-top: 32px;
  background-color: ${colors.white};
  line-height: 21px;
  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      margin-left: 46px;
      padding: 48px 48px 60px;
      margin-top: 0;
    `
  )}
`

const StyledRow = styled(GridRow)`
  padding: 0;
`

const Layout = styled.div`
  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      display: flex;
      width: 100%;
      flex-wrap: nowrap;
      justify-content: start;
      align-items: start;
      flex-basis: calc((100% - 220px) / 12 * 10 + 180px);
      max-width: calc((100% - 220px) / 12 * 10 + 180px);
      margin-left: calc(((100% - 220px) / 12 + 20px) * 1);
    `
  )}
`

const StyledButton = styled(Button)`
  margin: 0 12px;
  margin-bottom: 24px;
  min-width: 160px;
`

const DesktopButtons = styled.div`
  display: none;

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      display: block;
    `
  )}
`

const MobileButtons = styled.div`
  display: block;
  margin: 0 auto;

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      display: none;
    `
  )}
`

const ButtonsWrapper = styled.div`
  margin-top: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      flex-direction: row;
      width: 100%;
      justify-content: center;
    `
  )}
`

const Header = styled.div`
  ${textStyles.body}
  color: ${colors.black};
  padding-bottom: 16px;
  border-bottom: 1px solid ${colors.black};
  width: 100%;
  margin-bottom: 24px;
`

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const FirstRow = styled.div`
  display: flex;
  margin: 24px 0 0;

  > *:first-child {
    margin-right: 12px;
  }
`

const EmailTitlePart = styled.b`
  padding-right: 8px;
`

const Email = styled(Link)`
  ${textStyles.body}
  color: ${colors.black};
`

const EmailBody = styled.div`
  ${textStyles.body}
  color: ${colors.black};
`

// const Paragraphs = styled(Text)``
// const End = styled(Text)``

// const Space = styled.div`
//   height: 24px;
//   width: 100%;
// `

export default EmailLayout
