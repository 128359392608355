import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import GatsbyImage, { FluidObject } from "gatsby-image"

// Utils
import { getAspectRatio } from "../utils/styleHelpers"

// Styling
import { applyResponsive, Breakpoint } from "../styles/responsive"
import colors from "../styles/colors"
import textStyles from "../styles/typography"
import { GridCol, GridRow } from "./grid"

// Components
import Text from "./Text"
import Link from "./Link"

// Constants
import { ASPECT_RATIO } from "../constants/previewCard"

// Types
import { IPreviewCard, Variant } from "../common/models/previewCard"

interface IProps extends IPreviewCard {
  variant: Variant
}

const PreviewCard: React.FCS<IProps> = ({
  variant,
  image,
  title,
  text,
  linkLabel,
  linkUrl,
  className,
}) => {
  const aspectRatios = ASPECT_RATIO[variant]
  const isStory = variant === "story"

  const [fluidObj, setFluidObj] = useState<FluidObject | FluidObject[]>()

  useEffect(() => {
    setFluidObj({
      ...image.fluid,
      aspectRatio: getAspectRatio(aspectRatios),
    })

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const handleResize = () => {
    // debounce
    let timeout

    clearTimeout(timeout)

    timeout = setTimeout(() => {
      setFluidObj({
        ...image.fluid,
        aspectRatio: getAspectRatio(aspectRatios),
      })
    }, 250)
  }

  return (
    <Container isStory={isStory} className={className}>
      {image.fluid && fluidObj && (
        <ImageWrapper
          isStory={isStory}
          {...(isStory && { m: 3, mMaxCols: 8, l: 5, lMaxCols: 10 })}
        >
          <StyledGatsbyImage
            fluid={fluidObj}
            alt={image.alt ?? ""}
            isStory={isStory}
          />
        </ImageWrapper>
      )}
      <ContentWrapper
        {...(isStory && { m: 5, mMaxCols: 8, l: 5, lMaxCols: 10 })}
      >
        {title && (
          <Text marginBottom={24} tag="h3" textStyle="subHeading">
            {title}
          </Text>
        )}
        {isStory && text && (
          <StyledText marginBottom={24} textStyle="bodySmall">
            {text}
          </StyledText>
        )}
        {linkLabel && linkUrl && (
          <StyledLink
            type="internal"
            caption={linkLabel}
            url={linkUrl}
            underlined
          />
        )}
      </ContentWrapper>
    </Container>
  )
}

const Container = styled(GridRow)<{ isStory: boolean }>`
  padding: 0;

  ${({ isStory }) =>
    applyResponsive(
      { from: Breakpoint.M },
      css`
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        ${!isStory && "flex-direction: column;"}
      `
    )}
`

const ImageWrapper = styled(GridCol)<{ isStory: boolean }>`
  width: 100%;

  ${({ isStory }) =>
    !isStory &&
    applyResponsive(
      { from: Breakpoint.M },
      css`
        flex: 0;
      `
    )}
`

const StyledGatsbyImage = styled(GatsbyImage)<{
  fluid: FluidObject | FluidObject[]
  isStory: boolean
}>`
  flex-shrink: 0;
  ${({ isStory }) => isStory && "height: 100%;"}
`

const ContentWrapper = styled(GridCol)`
  padding: 16px;
  width: 100%;
  background-color: ${colors.neutral};

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      flex-grow: 1;
      display: flex;
      padding: 24px;
    `
  )}

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      padding: 32px;
    `
  )}
`

const StyledText = styled(Text)`
  ${applyResponsive(
    { to: Breakpoint.L },
    css`
      display: none;
    `
  )}

  flex-grow: 1;
`

const StyledLink = styled(Link)`
  ${textStyles.labelSmall}

  ${applyResponsive({ from: Breakpoint.M }, textStyles.label)}
`

export default PreviewCard
