import React from "react"
import styled, { css } from "styled-components"

// Styling
import { applyResponsive, Breakpoint } from "../styles/responsive"
import colors from "../styles/colors"
import textStyles from "../styles/typography"

// Components
import { GridRow, GridCol } from "./grid"
import Text from "./Text"

// Types
import Container from "./Container"

import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"
import { IShareCard } from "../common/models/socialCard"

const CreateSocialMediaPost: React.FCS<IShareCard> = ({ title, content }) => {
  const link = "https://www.notonourborderwatch.com/"

  const renderEmailButtons = () => (
    <SocialIcons>
      <FacebookShareButton url={link}>
        <FacebookIcon size={64} round={true} />
      </FacebookShareButton>
      <TwitterShareButton url={link}>
        <TwitterIcon size={64} round={true} />
      </TwitterShareButton>
      <LinkedinShareButton url={link}>
        <LinkedinIcon size={64} round={true} />
      </LinkedinShareButton>
      <WhatsappShareButton url={link}>
        <WhatsappIcon size={64} round={true} />
      </WhatsappShareButton>
    </SocialIcons>
  )

  return (
    <PageWrapper>
      <PageContainer>
        <ContentWrapper>
          <Layout>
            <StyledRow>
              <GridCol s={2} m={6} mOffsetLeft={2} l={6} lOffsetLeft={0}>
                <Description>
                  <Title tag="h3" textStyle="title" marginBottom={24}>
                    {title}
                  </Title>
                  <Content
                    dangerouslySetInnerHTML={{ __html: content }}
                  ></Content>
                  <DesktopButtons>{renderEmailButtons()}</DesktopButtons>
                </Description>
              </GridCol>
              <GridCol s={2} m={6} mOffsetLeft={2} l={5} lOffsetLeft={1}>
                <Letter>
                  <img src="https://noobw.cdn.prismic.io/noobw/fddf66a2-1bbe-4075-832e-377481e44b54_header.svg" />
                </Letter>
              </GridCol>
            </StyledRow>
          </Layout>
        </ContentWrapper>
      </PageContainer>
    </PageWrapper>
  )
}

const PageWrapper = styled.div`
  width: 100%;
  background-color: ${colors.neutral};
  padding: 32px 0 72px;

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      padding: 32px 20px 72px;
    `
  )}
`

const PageContainer = styled(Container)`
  margin: 0 auto;
  color: ${colors.white};
  display: flex;

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      padding: 40px 0;
    `
  )}

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      display: flex;
      justify-content: space-between;
      padding: 85px 0;
    `
  )}
`

const ContentWrapper = styled(GridRow)`
  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      flex-grow: 0;
      width: 100%;
    `
  )}
`

const Title = styled(Text)``

const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-right: 66px;
  line-height: 28px;
`

const Letter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  padding: 16px;
  margin-top: 32px;
  background-color: ${colors.white};
  line-height: 21px;
  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      margin-left: 46px;
      padding: 48px 48px 60px;
      margin-top: 0;
    `
  )}
`

const StyledRow = styled(GridRow)`
  padding: 0;
`

const Layout = styled.div`
  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      display: flex;
      width: 100%;
      flex-wrap: nowrap;
      justify-content: start;
      align-items: start;
      flex-basis: calc((100% - 220px) / 12 * 10 + 180px);
      max-width: calc((100% - 220px) / 12 * 10 + 180px);
      margin-left: calc(((100% - 220px) / 12 + 20px) * 1);
    `
  )}
`

const DesktopButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      justify-content: flex-start;
    `
  )}
`

const SocialIcons = styled.div`
  margin-top: 40px;

  > * {
    margin-right: 16px;
  }
`

const Content = styled.div`
  ${textStyles.body}
  color: ${colors.black};
`

// const Paragraphs = styled(Text)``
// const End = styled(Text)``

// const Space = styled.div`
//   height: 24px;
//   width: 100%;
// `

export default CreateSocialMediaPost
