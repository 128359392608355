import React, { useEffect, useState } from "react"
import Link from "./Link"
import styled, { css } from "styled-components"
import { scroller } from "react-scroll"

// Styling
import colors from "../styles/colors"

// Components
import { applyResponsive } from "../styles/responsive"
import { Breakpoint } from "../styles/responsive"
import { getOffset, links } from "../utils/navHelper"

const MIN_LOGO_WIDTH_PERCENTAGE = 56

const Header: React.FCS = ({ className }) => {
  // @ts-ignore
  const [navLinkSelected, setNavLinkSelected] = useState<string>("")
  const [logoWidth, setLogoWidth] = useState<number>(100)
  const [isMobileNavFixed, setIsMobileNavFixed] = useState<boolean>(false)

  const handleScroll = () => {
    if (window.innerWidth < Breakpoint.NAV) {
      const offset = getOffset(
        document.getElementById("navigation-links-scroller")
      )
      const offsetReplacer = getOffset(
        document.getElementById("navigation-links-scroller-replacer")
      )
      if (window.scrollY > offset.top && !isMobileNavFixed) {
        setIsMobileNavFixed(true)
      }
      if (window.scrollY < offsetReplacer.top && isMobileNavFixed) {
        setIsMobileNavFixed(false)
      }
    } else {
      const newLogoWidth = 100 - window.scrollY * 0.1
      if (
        newLogoWidth < MIN_LOGO_WIDTH_PERCENTAGE &&
        logoWidth !== MIN_LOGO_WIDTH_PERCENTAGE
      ) {
        setLogoWidth(MIN_LOGO_WIDTH_PERCENTAGE)
      } else if (newLogoWidth > MIN_LOGO_WIDTH_PERCENTAGE) {
        setLogoWidth(newLogoWidth)
      }
    }
  }

  const handleResize = () => {
    if (window.innerWidth < Breakpoint.NAV) {
      setLogoWidth(100)
    } else {
      handleScroll()
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })
    window.addEventListener("resize", handleResize, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
      window.removeEventListener("resize", handleResize)
    }
  }, [isMobileNavFixed, logoWidth])

  const clickOnLink = (id: string) => {
    scroller.scrollTo(id, {
      smooth: true,
      offset: window.innerWidth < Breakpoint.NAV ? -110 : -150,
      duration: 500,
    })
    // This is intentionally not updated, as keeping it highlighted while scrolling looks weird
    // setNavLinkSelected(id)
  }

  return (
    <>
      <Container className={className}>
        <Title>
          <LogoImage
            width={logoWidth + "%"}
            src={require("../images/noobw-logo-subtitle.svg")}
          ></LogoImage>
        </Title>
        <LinksScroller
          id="navigation-links-scroller"
          isMobileNavFixed={isMobileNavFixed}
        >
          <InsideScroll>
            <Links>
              {links.map((link) => (
                <NavLink
                  key={"nav-item-" + link.id}
                  type="internal"
                  caption={link.title}
                  onClick={() => clickOnLink(link.id)}
                  isActive={navLinkSelected === link.id}
                />
              ))}
            </Links>
          </InsideScroll>
        </LinksScroller>
        <LinksSpaceReplacer
          isVisible={isMobileNavFixed}
          id="navigation-links-scroller-replacer"
        ></LinksSpaceReplacer>
      </Container>
      <SpaceReplacer></SpaceReplacer>
    </>
  )
}

const InsideScroll = styled.div`
  width: 100%;
  overflow-x: scroll;
  padding: 10px 0;

  ${applyResponsive(
    { from: Breakpoint.NAV },
    css`
      overflow: auto;
    `
  )}
`

const LinksScroller = styled.div<{ isMobileNavFixed: boolean }>`
  width: 100%;
  background: ${colors.white};
  position: relative;
  z-index: 4;

  ::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 14px;
    height: 100%;
    background: linear-gradient(
      270deg,
      #ffffff 47.46%,
      rgba(255, 255, 255, 0) 126.56%
    );
  }

  ${applyResponsive(
    { from: Breakpoint.NAV },
    css`
      overflow: hidden;
      ::after {
        display: none;
      }
    `
  )}

  ${({ isMobileNavFixed }) =>
    isMobileNavFixed &&
    css`
      ${applyResponsive(
        { to: Breakpoint.NAV },
        css`
          position: fixed;
          padding: 0 4%;
          top: 0;
          left: 0;

          ::after {
            margin-right: 4%;
          }
        `
      )}
    `}
`

const LinksSpaceReplacer = styled.div<{ isVisible: boolean }>`
  display: none;
  height: 58px;
  ${({ isVisible }) =>
    isVisible &&
    css`
      display: block;
    `}
`

const Links = styled.nav`
  min-width: 700px;
  text-align: left;
  padding: 0;
  margin-top: 14px;

  ${applyResponsive(
    { from: Breakpoint.NAV },
    css`
      margin-top: 0;
      margin-left: -24px;
      text-align: right;
    `
  )}
`

const Container = styled.header`
  margin-bottom: 1.45rem;
  padding: 1rem;
  display: flex;
  background: ${colors.white};
  padding: 20px 4%;
  width: 100%;
  top: 0;
  z-index: 5;
  flex-direction: column;
  align-items: baseline;

  ${applyResponsive(
    { from: Breakpoint.NAV },
    css`
      padding: 16px 4%;
      position: fixed;
    `
  )}
  ${applyResponsive(
    { from: Breakpoint.NAV },
    css`
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    `
  )}
`

const SpaceReplacer = styled.div`
  height: 0;

  ${applyResponsive(
    { from: Breakpoint.NAV },
    css`
      height: 250px;
    `
  )}
`

const Title = styled.div`
  margin-bottom: 0;
  max-width: 580px;
  text-align: center;
`

const LogoImage = styled.img`
  margin: 0 auto;
  margin-bottom: 0;
  vertical-align: middle;

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      margin-bottom: 58px;
    `
  )}
  ${applyResponsive(
    { from: Breakpoint.NAV },
    css`
      margin-bottom: 0;
    `
  )}
`

export const NavLink = styled(Link)`
  margin-left: 24px;
  text-decoration: none;

  &:first-child {
    margin-left: 0;
  }
`

export default Header
