import React, { useState } from "react"
import styled, { css } from "styled-components"

// Components
import { GridRow, GridCol } from "./grid"
import Button, { ButtonVariant } from "./Button"
import SectionHeader from "./SectionHeader"
import Text from "./Text"

// Styling
import colors from "../styles/colors"
import { applyResponsive, Breakpoint } from "../styles/responsive"

// Types
import { IManifestData } from "../common/models/manifest"

const Manifest: React.FC<IManifestData> = ({
  content,
  readMoreContent,
  questionsList,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const readMore = () => {
    setIsExpanded(true)
  }

  return (
    <Container id="manifest">
      <SectionHeader title="Manifest"></SectionHeader>
      <GridRow>
        <GridCol s={2} mOffsetLeft={2} m={6} lOffsetLeft={2} l={8}>
          <MainContent
            bottomSpacing={!isExpanded}
            dangerouslySetInnerHTML={{ __html: content }}
          ></MainContent>
        </GridCol>
      </GridRow>
      <HiddenPart expanded={isExpanded}>
        <GridRow>
          <GridCol s={2} mOffsetLeft={2} m={6} lOffsetLeft={2} l={8}>
            <MainContent
              bottomSpacing={isExpanded}
              dangerouslySetInnerHTML={{ __html: readMoreContent }}
            ></MainContent>
          </GridCol>
        </GridRow>
        <BottomContent>
          <GridRow>
            <GridCol s={2} mOffsetLeft={2} m={3} lOffsetLeft={2} l={2}>
              <Text textStyle="subHeading" color="white">
                So Europe, do please explain...
              </Text>
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol s={2} mOffsetLeft={2} m={6} lOffsetLeft={2} l={8}>
              <ReasonsList>
                {questionsList.map((element, index) => (
                  <ReasonElement key={"manifest-reason-" + index}>
                    <Text textStyle="bodyLarge" color="white">
                      {element.question}
                    </Text>
                  </ReasonElement>
                ))}
              </ReasonsList>
            </GridCol>
          </GridRow>
        </BottomContent>
      </HiddenPart>
      {!isExpanded && (
        <ReadMoreButton
          caption="Read more"
          variant={ButtonVariant.Filled}
          color="primary"
          textColor="black"
          hoverColor="primaryLight"
          activeBorderColor="black"
          onClick={() => readMore()}
        />
      )}
    </Container>
  )
}

const ReadMoreButton = styled(Button)`
  margin: 0 auto;
`

const HiddenPart = styled.div<{
  expanded: boolean
}>`
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s ease;

  ${(props) =>
    props.expanded &&
    css`
      opacity: 1;
      max-height: 999em;
    `}
`

const BottomContent = styled.div`
  padding: 56px 0;
  background-color: ${colors.background};
  color: ${colors.white};
`

const Container = styled.div``

const MainContent = styled.div<{ bottomSpacing: boolean }>`
  ${({ bottomSpacing }) =>
    bottomSpacing &&
    css`
      margin-bottom: 24px;

      ${applyResponsive(
        { from: Breakpoint.M },
        css`
          margin-bottom: 56px;
        `
      )}

      ${applyResponsive(
        { from: Breakpoint.L },
        css`
          margin-bottom: 80px;
        `
      )}
    `}
`

const ReasonsList = styled.ul`
  margin-top: 24px;
  list-style-type: none;

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      margin-left: 48px;
    `
  )}
`

const ReasonElement = styled.li`
  margin-bottom: 1.45rem;
`

export default Manifest
