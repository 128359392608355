import React from "react"
import styled, { css } from "styled-components"
import { graphql } from "gatsby"

// Styling
import { applyResponsive, Breakpoint } from "../styles/responsive"

// Components
import Seo from "../components/Seo"
import Header from "../components/Header"
import Container from "../components/Container"
import Intro from "../components/Intro"
import Manifest from "../components/Manifest"
import OurProjects from "../components/OurProjects"
import YouCanHelp from "../components/YouCanHelp"
import AboutUs from "../components/AboutUs"
import NewsPreviews from "../components/NewsPreviews"
import StoryPreviews from "../components/StoryPreviews"

// Types
import { IManifestData } from "../common/models/manifest"
import { IPreviewCard } from "../common/models/previewCard"
import { INewsPreviews } from "../common/models/newsPreviews"
import { IStoryPreviews } from "../common/models/storyPreviews"
import { IAboutUs, ILogo } from "../common/models/aboutUs"
import { IOurProjects, IProject } from "../common/models/ourProjects"
import { Helmet } from "react-helmet"

export const query = graphql`
  {
    prismicHomepage {
      data {
        metaTitle: meta_title
        metaDescription: meta_description
        intro {
          html
        }
        ourProjectsList: our_projects_list {
          ourProjects: our_projects {
            document {
              ... on PrismicOurProjects {
                data {
                  image {
                    alt
                    fluid {
                      aspectRatio
                      base64
                      sizes
                      src
                      srcSet
                      srcSetWebp
                      srcWebp
                    }
                  }
                  title {
                    text
                  }
                  content {
                    html
                  }
                }
              }
            }
          }
        }
        newsList: news_list {
          news {
            document {
              ... on PrismicNews {
                uid
                data {
                  title {
                    text
                  }
                  image {
                    fluid {
                      aspectRatio
                      base64
                      sizes
                      src
                      srcSet
                      srcSetWebp
                      srcWebp
                    }
                    alt
                  }
                }
              }
            }
          }
        }
        stories {
          story {
            document {
              ... on PrismicStories {
                uid
                data {
                  title {
                    text
                  }
                  image {
                    fluid {
                      aspectRatio
                      base64
                      sizes
                      src
                      srcSet
                      srcSetWebp
                      srcWebp
                    }
                    alt
                  }
                  previewText: preview_text {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicManifest {
      data {
        content {
          html
        }
        readMoreContent: read_more_content {
          html
        }
        questionsList: questions_list {
          question
        }
      }
    }
    prismicAboutUs {
      data {
        title: about_us {
          text
        }
        content {
          html
        }
        initiatorsTitle: initiators_title {
          text
        }
        secondaryInitiatorsTitle: secondary_initiators_title {
          text
        }
        initiators {
          logo {
            alt
            url
          }
        }
        secondary_initiators {
          logo {
            alt
            url
          }
        }
      }
    }
    prismicYouCanHelp {
      data {
        title {
          text
        }
        content {
          html
        }
        email_section_title {
          text
        }
        email_description {
          html
        }
        email_subject {
          text
        }
        email_body {
          html
        }
        email_recipient
        socialsCardTitle: socials_card_title {
          text
        }
        socialsCardContent: social_card_content {
          html
        }
        shareSocialHeader: share_social_header {
          text
        }
        shareSocialContent: share_on_social_content {
          html
        }
      }
    }
    prismicSocialMediaChannels {
      data {
        socialMedia: social_media {
          type
          url
        }
      }
    }
  }
`

interface IProps {
  data: any
}

const IndexPage: React.FC<IProps> = ({ data }) => {
  // const mainMetaTitle = data.prismicHomepage.data.metaTitle ?? "Home"
  // const mainMetaDescription = data.prismicHomepage.data.metaDescription

  const introText: string = data.prismicHomepage.data.intro.html

  const rawManifestData = data.prismicManifest.data

  const manifestData: IManifestData = {
    content: rawManifestData.content.html,
    readMoreContent: rawManifestData.readMoreContent.html,
    questionsList: rawManifestData.questionsList,
  }

  const formattedOurProjectsData: IProject[] = data.prismicHomepage.data.ourProjectsList.map(
    ({ ourProjects }: any) => ({
      image: {
        fluid: ourProjects.document.data.image.fluid,
        alt: ourProjects.document.data.image.alt,
      },
      title: ourProjects.document.data.title.text,
      content: ourProjects.document.data.content.html,
    })
  )

  const ourProjectsData: IOurProjects = {
    projects: formattedOurProjectsData,
  }

  const rawYouCanHelpData = data.prismicYouCanHelp.data
  const { socialMedia } = data.prismicSocialMediaChannels.data

  const youCanHelpData = {
    title: rawYouCanHelpData.title.text,
    content: rawYouCanHelpData.content.html,
    emailSectionTitle: rawYouCanHelpData.email_section_title.text,
    emailDescription: rawYouCanHelpData.email_description.html,
    emailRecipient: rawYouCanHelpData.email_recipient,
    emailSubject: rawYouCanHelpData.email_subject.text,
    emailBody: rawYouCanHelpData.email_body.html,
    socialCard: {
      title: rawYouCanHelpData.socialsCardTitle.text,
      content: rawYouCanHelpData.socialsCardContent.html,
      socialMedia,
    },
    shareCard: {
      title: rawYouCanHelpData.shareSocialHeader.text,
      content: rawYouCanHelpData.shareSocialContent.html,
    },
  }

  const rawAboutUsData = data.prismicAboutUs.data

  const initiatorLogos: ILogo[] = rawAboutUsData.initiators.map(
    (item: any) => ({
      url: item.logo.url,
      alt: item.logo.alt,
    })
  )

  const secondaryInitiatorLogos: ILogo[] = rawAboutUsData.secondary_initiators.map(
    (item: any) => ({
      url: item.logo.url,
      alt: item.logo.alt,
    })
  )

  const aboutUsData: IAboutUs = {
    title: rawAboutUsData.title.text,
    text: rawAboutUsData.content.html,
    initiatorsTitle: rawAboutUsData.initiatorsTitle.text,
    initiatorLogos,
    secondaryInitiatorsTitle: rawAboutUsData.secondaryInitiatorsTitle.text,
    secondaryInitiatorLogos,
  }

  const formattedNewsData: IPreviewCard[] = data.prismicHomepage.data.newsList.map(
    ({ news }: any) => ({
      image: news.document.data.image,
      title: news.document.data.title.text,
      linkLabel: "Read more",
      linkUrl: `/news/${news.document.uid}`,
    })
  )

  const newsPreviewsData: INewsPreviews = {
    news: formattedNewsData,
  }

  const formattedStoriesData: IPreviewCard[] = data.prismicHomepage.data.stories.map(
    ({ story }: any) => ({
      image: story.document.data.image,
      title: story.document.data.title.text,
      text: story.document.data.previewText.text,
      linkLabel: "Read more",
      linkUrl: `/story/${story.document.uid}`,
    })
  )

  const storyPreviewsData: IStoryPreviews = {
    stories: formattedStoriesData,
  }

  const metaImage =
    "https://images.prismic.io/noobw/2013eb81-dd2d-4ea6-b86f-c3b2eeccc2b2_icon-1000px.png?auto=compress,format"
  const metaWebsite = "https://www.notonourborderwatch.com/"
  const metaTitle = "Not on our border watch"
  const metaDescription =
    "Together we can raise awareness, hold the European Union accountable and change this system of violation and oppression. As European citizens, we must not only ask Europe for an explanation, but above all demand that this system in which fundamental rights are ignored be changed."
  return (
    <>
      <Seo title={metaTitle} description={metaDescription} />
      <Helmet>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />
        <meta itemProp="image" content={metaImage} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={metaWebsite} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImage} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={metaWebsite} />
        <meta property="twitter:title" content={metaTitle} />
        <meta property="twitter:description" content={metaDescription} />
        <meta property="twitter:image" content={metaImage} />
      </Helmet>
      <Header />
      <Container>
        <Intro text={introText}></Intro>
      </Container>
      <SeparatorImage src={require("../images/beeldmerk.svg")} />
      <SeparatorImageThin src={require("../images/beeldmerk-thin.svg")} />
      <Container>
        <Manifest {...manifestData} />
      </Container>
      <Container>
        <OurProjects {...ourProjectsData} />
      </Container>
      <YouCanHelp {...youCanHelpData} />
      <Container>
        <AboutUs {...aboutUsData} />
      </Container>
      <Container>
        <NewsPreviews {...newsPreviewsData} />
      </Container>
      <Container>
        <StoryPreviews {...storyPreviewsData} />
      </Container>
    </>
  )
}

const SeparatorImage = styled.img`
  width: 100%;

  ${applyResponsive(
    { from: Breakpoint.XXL },
    css`
      display: none;
    `
  )}
`

const SeparatorImageThin = styled.img`
  width: 100%;

  ${applyResponsive(
    { to: Breakpoint.XXL },
    css`
      display: none;
    `
  )}
`

export default IndexPage
