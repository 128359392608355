import React from "react"
import styled, { css } from "styled-components"

// Components
import Text from "./Text"

// Styling
import colors from "../styles/colors"
import { applyResponsive, Breakpoint } from "../styles/responsive"

interface IProps {
  title: string
}

export const SectionHeader: React.FCS<IProps> = (props) => {
  return (
    <TextWrapper>
      <Text className={props.className} textStyle="heading" textAlign="center">
        {props.title}
      </Text>
    </TextWrapper>
  )
}

const TextWrapper = styled.div`
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 24px;
  margin-top: 56px;

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      margin-bottom: 40px;
      margin-top: 140px;
    `
  )}

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      margin-bottom: 80px;
      margin-top: 180px;
    `
  )}

  & > * {
    position: relative;
    display: inline-block;
    padding: 0 8px 4px;
    background: linear-gradient(to top, ${colors.primary} 42%, white 0%);
  }
`
export default SectionHeader
