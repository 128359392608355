interface NavLink {
  title: string
  id: string
}

export const links: NavLink[] = [
  {
    title: "MANIFEST",
    id: "manifest",
  },
  {
    title: "OUR PROJECTS",
    id: "our_projects",
  },
  {
    title: "YOU CAN HELP",
    id: "you_can_help",
  },
  {
    title: "ABOUT US",
    id: "about_us",
  },
  {
    title: "NEWS & STORIES",
    id: "news",
  },
]

export const getOffset = (el: any) => {
  const rect = el.getBoundingClientRect()
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
  }
}
