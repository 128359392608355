import React from "react"
import styled, { css } from "styled-components"

// Components
import { FieldMetaProps } from "formik"

// Styling
import colors from "../styles/colors"
// import textStyles from "../styles/typography"
// import { fastTransition } from "../styles/effects"

// // Types
// import { Color } from "../common/models/colors"

export interface IFormControlProps {
  label?: string // The label above the field
  additionalLabel?: string // Additional label, just underneath the main one
  disabled?: boolean
  defaultValue?: string | number
  errorMessage?: string
  meta?: FieldMetaProps<any>
  onChange?: (e?: any) => void
  handleChange?: (e?: any) => void
  value?: string | number
  type?: string
}
export enum InputType {
  Text = "text",
  Password = "password",
}
export interface IProps extends IFormControlProps {
  type?: InputType
  placeholder?: string
  className?: string
  maxLength?: number
  button?: {
    label: string
    handleClick: () => void
    disabled?: boolean
  }
}
const Input = (props: IProps) => {
  const type = props.type || InputType.Text
  const errorMessage =
    props.meta && props.meta.error && props.meta.touched
      ? props.meta.error
      : props.errorMessage
  return (
    <InputWrapper
      className={props.className}
      disabled={props.disabled}
      errorMessage={errorMessage}
    >
      {props.label && <InputLabel>{props.label}</InputLabel>}
      {props.label && props.additionalLabel && (
        <InputAdditionalLabel>{props.additionalLabel}</InputAdditionalLabel>
      )}
      <InputInnerWrapper>
        <StyledInput type={type} {...props} />
        {props.maxLength && (
          <StyledText>
            {(props.meta?.value && props.meta?.value.length) || 0} /{" "}
            {props.maxLength}
          </StyledText>
        )}
      </InputInnerWrapper>
      <InputError>{errorMessage}</InputError>
    </InputWrapper>
  )
}
const InputLabel = styled.label`
  display: block;
  margin: 0 0 8px;
  padding-left: 1px;
  color: ${colors.black};
`

const InputAdditionalLabel = styled.p`
  margin: 0 0 8px;
  color: ${colors.black};
`

const InputInnerWrapper = styled.div`
  position: relative;
`
const StyledText = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`
const StyledInput = styled.input`
  display: block;
  width: 100%;
  padding: 12px 16px;
  border: 1px solid ${colors.black};
  outline: 0;
  color: ${colors.black};
  background: ${colors.white};
  transition: border-color 0.3s ease;
  &:disabled {
    border-color: ${colors.black};
    pointer-events: none;
  }
  &:hover {
    border-color: ${colors.black};
  }
  ::placeholder {
    color: ${colors.black};
    opacity: 1;
  }
  &:focus {
    border-color: ${colors.black};
    & + * path {
      fill: ${colors.black};
    }
  }
`
const InputWrapper = styled.div<
  Pick<IFormControlProps, "disabled" | "errorMessage">
>`
  position: relative;
  display: block;
  margin-bottom: 24px;
  ${({ errorMessage }) =>
    errorMessage &&
    css`
      ${StyledInput} {
        border-color: ${colors.black};
        & + * path {
          fill: ${colors.black};
        }
      }
      ${InputError} {
        display: block;
      }
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      ${InputLabel}, ${InputAdditionalLabel} {
        color: ${colors.black};
      }
    `}
`
const InputError = styled.p`
  width: 100%;
  display: none;
  margin: 8px 0 0;
  padding-left: 1px;
  color: ${colors.black};
`
export default Input
