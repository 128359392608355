import React from "react"
import styled, { css } from "styled-components"

// Components
import SectionHeader from "./SectionHeader"
import { GridRow, GridCol } from "./grid"
import Text from "./Text"

// Types
import { IAboutUs } from "../common/models/aboutUs"
import { applyResponsive, Breakpoint } from "../styles/responsive"

const AboutUs: React.FC<IAboutUs> = ({
  title,
  text,
  initiatorsTitle,
  initiatorLogos,
  secondaryInitiatorsTitle,
  secondaryInitiatorLogos,
}) => (
  <section id="about_us">
    <SectionHeader title={title} />
    <GridRow>
      <GridCol m={6} mOffsetLeft={2} l={8} lOffsetLeft={2}>
        <Content dangerouslySetInnerHTML={{ __html: text }} />
        {initiatorLogos.length > 0 && (
          <>
            {initiatorsTitle && (
              <InitiatorsTitle tag="h3" marginBottom={40} textStyle="title">
                {initiatorsTitle}
              </InitiatorsTitle>
            )}
            {initiatorLogos.length > 0 && (
              <LogoWrapper>
                {initiatorLogos.map((logo, i) => (
                  <ImgBox>
                    <StyledImg
                      key={`logo-${i}`}
                      src={logo.url}
                      alt={logo.alt ?? ""}
                    />
                  </ImgBox>
                ))}
              </LogoWrapper>
            )}
            {secondaryInitiatorsTitle && (
              <InitiatorsTitle tag="h3" marginBottom={40} textStyle="title">
                {secondaryInitiatorsTitle}
              </InitiatorsTitle>
            )}
            {secondaryInitiatorLogos.length > 0 && (
              <SmallLogoWrapper>
                {secondaryInitiatorLogos.map((logo, i) => (
                  <ImgBox>
                    <StyledImg
                      key={`logo-${i}`}
                      src={logo.url}
                      alt={logo.alt ?? ""}
                    />
                  </ImgBox>
                ))}
              </SmallLogoWrapper>
            )}
          </>
        )}
      </GridCol>
    </GridRow>
  </section>
)

const Content = styled.div`
  :not(:last-child) {
    margin-bottom: 40px;
  }

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      :not(:last-child) {
        margin-bottom: 64px;
      }
    `
  )}
`

const InitiatorsTitle = styled(Text)`
  text-align: center;
  margin: 54px 0 32px;

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      text-align: left;
    `
  )}
`

const LogoWrapper = styled.div`
  display: grid;
  flex-wrap: wrap;
  grid-gap: 60px 120px;
  align-items: center;
  grid-template-columns: repeat(1, 1fr);
  width: 40%;
  margin: 0 auto;

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
    `
  )}

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
    `
  )}
`

const SmallLogoWrapper = styled(LogoWrapper)`
  grid-template-columns: repeat(2, 1fr);
  width: 80%;
  grid-gap: 60px;
  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
      grid-gap: 60px 100px;
    `
  )}

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      grid-template-columns: repeat(4, 1fr);
      width: 100%;
    `
  )}
`

const ImgBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledImg = styled.img`
  margin-block-start: 0;
  margin-block-end: 0;
)}
`

export default AboutUs
