import React, { useState } from "react"
import styled, { css } from "styled-components"

// Styling
import { applyResponsive, Breakpoint } from "../styles/responsive"

// Components
import { GridCol, GridRow } from "./grid"
import SectionHeader from "./SectionHeader"
import PreviewCard from "./PreviewCard"
import Button, { ButtonVariant } from "./Button"

// Constants
import {
  INITIAL_ITEM_COUNT,
  NUMBER_OF_ITEMS_TO_ADD,
} from "../constants/previews"

// Types
import { IStoryPreviews } from "../common/models/storyPreviews"
import { IPreviewCard } from "../common/models/previewCard"

const StoryPreviews: React.FCS<IStoryPreviews> = ({ stories }) => {
  const [previewsToShow, setPreviewsToShow] = useState<IPreviewCard[]>(
    stories.slice(0, INITIAL_ITEM_COUNT)
  )

  const handleShowMore = () => {
    setPreviewsToShow(() => {
      const currentDisplayedNewsCount = previewsToShow.length
      const newDisplayedNewsCount =
        currentDisplayedNewsCount + NUMBER_OF_ITEMS_TO_ADD

      return stories.slice(0, newDisplayedNewsCount)
    })
  }

  return (
    <section>
      <SectionHeader title="STORIES" />
      <GridRow>
        <PreviewsWrapper s={2} m={8} mOffsetLeft={1} l={10} lOffsetLeft={1}>
          {previewsToShow.map((storyItem) => (
            <StyledPreviewCard
              key={storyItem.linkUrl}
              variant="story"
              {...storyItem}
            />
          ))}
        </PreviewsWrapper>
        {stories.length > INITIAL_ITEM_COUNT && (
          <GridCol s={2} m={3} mOffsetLeft={3.5}>
            <StyledButton
              caption="Show more stories"
              variant={ButtonVariant.Filled}
              color="black"
              textColor="white"
              hoverColor="white"
              hoverTextColor="black"
              hoverBorderColor="black"
              activeBorderColor="black"
              onClick={handleShowMore}
              disabled={stories.length <= previewsToShow.length}
            />
          </GridCol>
        )}
      </GridRow>
    </section>
  )
}

const PreviewsWrapper = styled(GridCol)`
  :not(:last-child) {
    margin-bottom: 24px;
  }

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      :not(:last-child) {
        margin-bottom: 40px;
      }
    `
  )}

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      :not(:last-child) {
        margin-bottom: 64px;
      }
    `
  )}
`

const StyledPreviewCard = styled(PreviewCard)`
  margin-bottom: 36px;

  :last-child {
    margin: 0;
  }

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      margin-bottom: 32px;
    `
  )}
`

const StyledButton = styled(Button)`
  width: 100%;
`

export default StoryPreviews
