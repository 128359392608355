import React, { useState } from "react"
import styled, { css } from "styled-components"

// Styling
import { applyResponsive, Breakpoint } from "../styles/responsive"
import colors from "../styles/colors"

// Components
import { GridCol, GridRow } from "./grid"
import Text from "./Text"
import Button, { ButtonVariant } from "./Button"
import { IEmailLayout } from "../common/models/emailLayout"
import Input from "./Input"
import Container from "./Container"
import { FOLLOW_ENDPOINT } from "../utils/api"
import { Link } from "gatsby"

const UpdatedWrapper: React.FCS<IEmailLayout> = (
  {
    //   title,
    //   content
  }
) => {
  const [statusText, setStatusText] = useState<string>("")
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [agreed, setAgreed] = useState<boolean>(false)

  const onSubmit = async () => {
    setStatusText("")
    setInProgress(true)

    const requestBody = {
      email,
      firstName,
      lastName,
    }

    try {
      const followRequest = await fetch(FOLLOW_ENDPOINT, {
        method: "POST",
        body: JSON.stringify(requestBody),
      })

      setInProgress(false)

      const response = await followRequest.json()

      if (followRequest.ok) {
        setFirstName("")
        setLastName("")
        setEmail("")
        setStatusText(response.message)
      } else {
        setStatusText(
          response.message || "Something wrong happened, please try again later"
        )
      }
      // this.setState({ formSubmitted: true })
    } catch (err) {
      console.error(`Error fetching server login:`, err)
    }
  }

  const validEmail = (unvalidatedEmail: string) => {
    const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/
    return regex.test(unvalidatedEmail)
  }

  const isButtonEnabled =
    agreed &&
    !inProgress &&
    firstName.length >= 2 &&
    lastName.length >= 2 &&
    email.length >= 4 &&
    validEmail(email)

  return (
    <PageContainer>
      <GridRow>
        <GridCol s={2} m={8} mOffsetLeft={1} l={6} lOffsetLeft={3}>
          <Description>
            <Title tag="h3" textStyle="title" marginBottom={24}>
              Stay updated about our projects
            </Title>
            <Text>
              Do you want to stay updated by e-mail about our projects or news
              items? Enter your e-mail and we will send you an update every now
              and then. Don’t worry, we will not spam you with e-mail, nor will
              we use your contact details for anything else (as stated in our{" "}
              <Link to="/privacy">privacy policy</Link>)
            </Text>
          </Description>
          <Fields>
            <FirstRow>
              <FirstInput
                label="First name"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              ></FirstInput>
              <SecondInput
                label="Last name"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              ></SecondInput>
            </FirstRow>
            <SecondRow>
              <ThirdInput
                label="E-mail address"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              ></ThirdInput>
            </SecondRow>
            <Checkbox>
              <Box
                type="checkbox"
                id="agree_to_email_subscription"
                onChange={(e) => setAgreed(e.target.checked)}
              ></Box>
              <Label htmlFor="agree_to_email_subscription">
                <Text>
                  By subscribing, I agree with the{" "}
                  <Link to="/privacy">privacy policy</Link>
                </Text>
              </Label>
            </Checkbox>
          </Fields>
          <ButtonsWrapper>
            <StyledButton
              caption="Keep me updated"
              variant={ButtonVariant.Filled}
              color="primary"
              textColor="black"
              hoverColor="primaryLight"
              activeBorderColor="black"
              disabled={!isButtonEnabled}
              onClick={() => onSubmit()}
            />
          </ButtonsWrapper>
          {statusText ? <MessageBox>{statusText}</MessageBox> : null}
        </GridCol>
      </GridRow>
    </PageContainer>
  )
}

const PageContainer = styled(Container)`
  padding: 40px 0;
  color: ${colors.white};

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      padding: 85px 0;
    `
  )}
`

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const FirstRow = styled.div`
  display: flex;
  margin: 24px 0;
`

const SecondRow = styled.div`
  display: flex;
`

const Description = styled(GridCol)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 28px;
`

const Title = styled(Text)``

const StyledButton = styled(Button)`
  margin: 0 auto;
`

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 42px;
`

const FirstInput = styled(Input)`
  width: 90%;
`
const SecondInput = styled(Input)`
  width: 100%;
`
const ThirdInput = styled(Input)`
  width: 100%;
`

const Checkbox = styled.div`
  display: flex;
  align-items: start;
  width: 100%;
  margin-top: 24px;
`

const Box = styled.input`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border: 1px solid ${colors.black};
`

const Label = styled.label`
  color: ${colors.black};
`

const MessageBox = styled.div`
  padding: 11px 16px;
  background-color: black;
  color: white;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.77;
  letter-spacing: normal;
  margin: 20px 0;
  margin-top: 36px;
`

export default UpdatedWrapper
