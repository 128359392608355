import React from "react"
import styled, { css } from "styled-components"

// Components
import { GridRow, GridCol } from "./grid"

// Styling
import colors from "../styles/colors"
import { applyResponsive, Breakpoint } from "../styles/responsive"
import Button, { ButtonVariant } from "./Button"
import { scroller } from "react-scroll"

interface IProps {
  text: string
}

const Intro: React.FC<IProps> = ({ text }) => {
  const youCanHelpClick = () => {
    scroller.scrollTo("you_can_help", {
      smooth: true,
      offset: window.innerWidth < Breakpoint.NAV ? -110 : -150,
      duration: 500,
    })
    // This is intentionally not updated, as keeping it highlighted while scrolling looks weird
    // setNavLinkSelected(id)
  }

  return (
    <Container>
      <GridRow>
        <VerticalBarCol m={1} l={1} mOffsetLeft={1} lOffsetLeft={2}>
          <VerticalBar></VerticalBar>
        </VerticalBarCol>
        <GridCol s={2} m={6} l={6}>
          <MainContent dangerouslySetInnerHTML={{ __html: text }}></MainContent>
        </GridCol>
      </GridRow>
      <CenterRow>
        <GridCol s={2} m={10} l={12}>
          <StyledButton
            caption="How you can help"
            variant={ButtonVariant.Filled}
            color="primary"
            textColor="black"
            hoverColor="primaryLight"
            activeBorderColor="black"
            onClick={() => youCanHelpClick()}
          />
        </GridCol>
      </CenterRow>
    </Container>
  )
}

const Container = styled(GridRow)`
  margin-top: 62px;
  margin-bottom: 128px;
`

const VerticalBarCol = styled(GridCol)`
  ${applyResponsive(
    { to: Breakpoint.S },
    css`
      display: none;
    `
  )}
`
const MainContent = styled.div``

const VerticalBar = styled.div`
  width: 16px;
  height: 100%;
  float: right;
  margin-right: 22px;
  background-color: ${colors.primary};
`

const StyledButton = styled(Button)`
  margin: 0 auto;
  margin-top: 42px;
  min-width: 160px;
`

const CenterRow = styled(GridRow)`
  text-align: center;
  width: 100%;
`

export default Intro
